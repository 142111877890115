import Unauthorized from "../../components/common/unauthorized/Unauthorized";

const UnauthorizedPage = () => {
  return (
    <>
      <Unauthorized />
    </>
  );
};

export default UnauthorizedPage;
