import UserTemplate from "../../views/UserTemplate";

const PrivacyPolicyPage = () => {
  return (
    <UserTemplate>
      <div>PrivacyPolicyPage</div>
    </UserTemplate>
  );
};

export default PrivacyPolicyPage;
